.MuiChip-root {
    font-size: 10px;
    font-weight: 700;
    // line-height: 10px;

    .MuiIcon-root {
        font-size: 14px;
    }

    &.ok {
        background-color: var(--c-success-light);
        color: var(--c-success-dark);

        .MuiIcon-root {
            color: var(--c-success-dark);
        }
    }

    &.info {
        background-color: var(--c-info-light);
        color: var(--c-info-dark);

        .MuiIcon-root {
            color: var(--c-info-dark);
        }
    }

    &.warning {
        background-color: var(--c-warning-light);
        color: var(--c-warning-dark);

        .MuiIcon-root {
            color: var(--c-warning-dark);
        }
    }

    &.error {
        background-color: var(--c-error-light);
        color: var(--c-error-dark);

        .MuiIcon-root {
            color: var(--c-error-dark);
        }
    }
}
