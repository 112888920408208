.uptime-bar-chart-container {
    .hover-cursor:hover {
        cursor: pointer;
    }

    .custom-tooltip {
        background-color: white;
        border: 1px solid var(--c-dark);
        padding: 0;
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius-small);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        font-size: var(--t-desktop-paragraph-xsmall);
        line-height: 17px;
        font-weight: 400;

        .custom-tooltip-header {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xx-sm);
            padding: var(--spacing-x-sm);

            .custom-tooltip-info {
                color: var(--c-gray-500);
            }

            .custom-tooltip-text {
                font-size: var(--t-desktop-paragraph-normal);
                font-weight: 500;
                line-height: 24px;
            }

            .custom-tooltip-date {
                display: flex;
                gap: var(--spacing-xx-sm);

                span {
                    color: var(--c-gray-400);
                }
            }
        }

        .custom-tooltip-details-btn {
            padding: var(--spacing-x-sm);
            border-top: 1px solid var(--c-gray-300);

            .MuiTypography-root {
                color: var(--c-primary);
                font-size: var(--t-desktop-paragraph-xsmall);
                font-weight: 400;
                line-height: 17px;
            }
        }
    }

    .custom-x-axis-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;

        .MuiTypography-root {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
        }
    }

    .custom-x-axis-line.show {
        opacity: 1;
    }

    .x-axis-hidden {
        transition: transform 0.2s ease-in-out;
        transform: translateY(-32px);
    }

    .x-axis-show {
        transform: translateY(0px);
    }

    &.big {
        height: 307px;
        transition: height 0.1s ease-in-out;
    }

    &.small {
        display: block;
        height: 275px;
    }
}
