.lang-selector {
    button {
        padding: 0;
        border: none;
        outline: none;
        span {
            margin: 0;
            padding-left: 0;
            font-size: 32px;
        }
    }

    ul {
        right: unset;
    }
}
