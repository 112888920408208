.scroll-box {
  overflow-y: scroll;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (width < 850px) {
    height: calc(100vh - 56px);
  }
}

.main-page-container {
  max-width: 850px !important;
  display: flex !important;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-xx-lg) var(--spacing-sm) !important;

  @media (width < 850px) {
    padding: var(--spacing-xxx-big) var(--spacing-sm) var(--spacing-x-lg)
      var(--spacing-sm) !important;
    /* min-width: 375px; */
  }
}
/* TODO: REMOVE */
.header-old {
  background: linear-gradient(90deg, #1a32a8, #009ce9 60%, #009ce9);
  color: white;
  padding: 20px 30px 20px 40px;
  border-radius: 5px 5px 0 0;
}

.channel-name-container {
  flex-grow: 2;
}

.channel-name {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 600;
}

@media (max-width: 449px) {
  .channel-name {
    margin-left: 0;
    margin-bottom: 0;
    justify-content: center;
  }
}

.platform-name {
  margin-left: 5px;
}

.MuiButton-root.button-monitor {
  border-radius: 50px;
  margin: 0.8rem;
  text-transform: capitalize;
  height: 33px;
  font-size: 12px;
}

p.last-info-text {
  color: #009be9;
  text-transform: capitalize;
}

span.channel-title {
  font-size: 0.95em;
  padding-left: 10px;
  font-weight: 500;
  width: 100%;
}

.filter-container {
  border-radius: 4px;
  padding: 0 2rem 2rem 2rem;
  background-color: #fcfcfc;
  margin: 20px;
  box-shadow: rgb(0 0 0 / 0%) 0 2px 1px -1px, rgb(0 0 0 / 0%) 0 1px 1px 0,
    rgb(0 0 0 / 12%) 0 1px 3px 0;
  display: grid;
}

.filters-dialog {
  width: 60vw;
  margin: 15% auto;
}

.csv-link {
  align-self: center;
  text-decoration: none;
}

.report-head {
  display: flex;
  align-items: center;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.MuiButton-root.button-refresh {
  font-size: 12px;
}

.report-container {
  margin: 20px;
  font-size: 14px;
}

.MuiPaper-root.alert {
  background-color: rgb(0, 155, 233);
  color: #ffffff;
  font-weight: bold;
  margin: 20px;
}

.MuiPaper-root.main-container {
  margin: 30px;
  padding-bottom: 10px;
  background-color: #fafafa;
  min-width: 200px;
}

.bold {
  font-weight: 600;
}

.MuiSkeleton-root.skeleton {
  margin-bottom: 5px;
}

.MuiAutocomplete-root.inputs,
.MuiFormControl-root.inputs {
  margin: 10px;
}

.inputs-flex {
  display: flex;
  flex-direction: row;
}

.MuiChip-root.chip-status {
  border-radius: 3px;
  line-height: 10px;
  width: 120px;
  font-size: 11px;
  font-family: Roboto, serif;
  padding: 4px 13px;
  height: 24px;
}

.card-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 1em;
}

.MuiCard-root.card-general {
  border-top: 0.5rem solid #009be9;
  border-radius: 6px 6px 0 0;
  width: 13rem;
}

.MuiCardContent-root.content-card {
  display: flex;
  align-items: center;
  height: 100%;
}

.card-info {
  margin-left: 1rem;
  padding: 0;
}

h4.channel-title {
  margin: 0;
}

.up-time {
  display: flex;
  align-items: center;
  height: 2rem;
}

h5.up-time-title {
  margin-left: 0.5rem;
}

.accordion-details {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  flex-direction: column;
}

.accordion-details-title {
  margin: 10px 30px;
}

.accordion-details-span {
  display: flex;
  font-size: 16px;
  padding-left: 5px;
  font-weight: 500;
  margin-bottom: 15px;
}

.accordion-summary {
  width: 100%;
  margin: 0.3rem 1.5rem;
}

.channel-link {
  flex-grow: 1;
}

@media (max-width: 449px) {
  .channel-link {
    text-align: center;
  }

  .MuiAccordionSummary-content {
    justify-content: center;
  }
}

.MuiAccordion-root.accordion-container {
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 70em;
  /*background-color: #fcfcfc;*/
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: rgb(0 0 0 / 0%) 0 2px 1px -1px, rgb(0 0 0 / 0%) 0 1px 1px 0,
    rgb(0 0 0 / 12%) 0 1px 3px 0;
}

.ok-color {
  color: #178038;
}

.problems-color {
  color: #d1191c;
}

.card-ok {
  border-top-color: rgb(68 211 44);
  border-top-width: 5px;
  border-top-style: inset;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.card-problems {
  border-top-color: #ff0004;
  border-top-width: 5px;
  border-top-style: inset;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.logs-container {
  margin: 10px 30px;
  font-size: 14px;
}

.logs-span {
  display: flex;
  font-size: 16px;
  padding-left: 5px;
  font-weight: 500;
  margin-bottom: 15px;
}

.logs-output {
  font-family: Monaco, serif;
  padding: 10px;
  background-color: #eeeeee;
  color: #6e6e69;
  font-style: oblique;
}

.log-response {
  font-weight: 600;
}

.tool-tip-container {
  background-color: #ffffffd4;
  border: 2px solid #f5f5f5;
  padding: 10px;
}

.tool-tip-span {
  padding-left: 15px;
  font-weight: 600;
}

.metric-summary {
  padding: 0 25px;
}

.channel-info-container {
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  align-items: center;
  justify-content: center;
}

.channel-info {
  font-size: small;
  text-align: center;
}

.channel-info-value {
  font-weight: 900;
  font-style: oblique;
  margin-left: 0.5rem;
}

.channel-info-value-not-response {
  font-weight: 900;
  font-style: oblique;
}

.MuiIcon-root.channel-logo {
  color: #304ffe;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.box-title {
  display: flex;
  gap: 0.4em;
  align-items: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.no-monitor {
  margin: 2em;
  padding: 2em;
}

.no-monitor-button {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.filter-title {
  font-weight: bold;
  font-size: 18px;
  padding: 1rem 0.5rem 0;
}

.filter-subtitle {
  font-size: 14px;
  padding: 1rem 0.5rem 1rem;
  font-weight: 530;
}

.no-metrics {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
