.history_page {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxx-big);
    align-items: flex-start;

    .history_content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-x-big);
        width: 100%;

        .history_header {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xx-sm);
            align-items: center;

            .MuiTypography-h1 {
                font-size: var(--t-desktop-headings-h1);
                font-weight: 400;
                line-height: 40px;
                color: var(--c-gray-900);
            }

            .MuiTypography-body1 {
                font-size: var(--t-desktop-paragraph-normal);
                font-weight: 400;
                line-height: 24px;
                color: var(--c-gray-900);
            }
        }

        .all_entries {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-x-big);
            width: 100%;

            .history_entry {
                background-color: white;
                padding: var(--spacing-x-big);
                border-radius: var(--border-radius-small);
                display: flex;
                gap: var(--spacing-sm);
                display: flex;
                flex-direction: column;

                .entry_content {
                    display: flex;
                    flex-direction: column;
                    gap: var(--spacing-sm);
                    color: var(--c-gray-900);

                    .MuiTypography-h4 {
                        font-size: var(--t-desktop-headings-h4);
                        font-weight: 400;
                        line-height: 28px;
                    }

                    .entry_text {
                        display: flex;
                        flex-direction: column;
                        gap: var(--spacing-sm);

                        .MuiTypography-h6 {
                            font-size: var(--t-desktop-headings-h6);
                            font-weight: 600;
                            line-height: 24px;
                        }

                        .MuiTypography-body1 {
                            font-size: var(--t-desktop-paragraph-small);
                            font-weight: 600;
                            line-height: 20px;
                        }

                        .MuiTypography-body2 {
                            font-size: var(--t-desktop-paragraph-small);
                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }

                .history_btn_see_more {
                    margin: auto;

                    .MuiIcon-root {
                        font-size: var(--t-button-normal);
                    }
                }
            }
        }
    }

    .full_story {
        background-color: white;
        border-radius: var(--border-radius-small);
        padding: var(--spacing-x-big);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-sm);
        width: 100%;

        .entry_content {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-sm);
            color: var(--c-gray-900);

            .MuiTypography-h4 {
                font-size: var(--t-desktop-headings-h4);
                font-weight: 400;
                line-height: 28px;
            }

            .entry_table {
                display: flex;
                gap: var(--spacing-x-big);
                justify-content: space-between;

                @media (max-width: 720px) {
                    flex-direction: column;
                }

                .entry_text {
                    display: flex;
                    flex-direction: column;
                    gap: var(--spacing-sm);

                    .MuiTypography-h6 {
                        font-size: var(--t-desktop-headings-h6);
                        font-weight: 600;
                        line-height: 24px;
                    }

                    .MuiTypography-body1 {
                        font-size: var(--t-desktop-paragraph-small);
                        font-weight: 600;
                        line-height: 20px;
                    }

                    .MuiTypography-body2 {
                        font-size: var(--t-desktop-paragraph-small);
                        font-weight: 400;
                        line-height: 20px;
                    }

                    .MuiTypography-caption {
                        font-size: var(--t-desktop-paragraph-small);
                        font-weight: 400;
                        line-height: 20px;
                        color: var(--c-gray-600);
                    }
                }

                .entry-right {
                    .MuiTypography-h6 {
                        width: 200px;
                        font-size: var(--t-desktop-headings-h6);
                        font-weight: 600;
                        line-height: 24px;
                    }

                    .MuiTypography-body2 {
                        font-size: var(--t-desktop-paragraph-small);
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                .mobile {
                    display: none;
                }

                @media (max-width: 720px) {
                    .entry-right {
                        display: none;
                    }
                    .mobile {
                        display: unset;
                    }
                }
            }
        }
    }
}
