$mobile: 850px;

.header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-x-big);
  border-bottom: 1px solid var(--c-gray-300);
  height: 64px;

  .mobile {
    display: none !important;
  }

  .division {
    border: 1px solid #e0e0e0;
    height: 29px;
  }

  .left-section {
    height: 26px;

    img {
      height: 100%;
    }
  }

  .mid-section {
    align-self: flex-end;

    .MuiTab-root {
      text-transform: none;
      font-size: var(--t-button-small);
      font-weight: 400;
      line-height: 20px;
      color: var(--c-gray-800);
    }
    .Mui-selected {
      color: var(--c-primary);
    }
  }

  .right-section {
    height: 40px;
    display: flex;
    align-items: center;
    gap: var(--spacing-x-big);

    .title {
      color: var(--c-primary);
      font-size: var(--t-desktop-headings-h6);
      font-weight: 500;
      line-height: 24px;
    }

    .page-profile {
      display: flex;
      align-items: center;
      gap: var(--spacing-x-big);

      .lang-selector {
        height: 40px;
        .lang-flags {
          padding: 0;
          // height: 40px;

          button {
            height: 40px;
            padding: 5px 10px;

            span {
              font-size: 22px;
            }
          }
        }
      }

      .MuiAvatar-root {
        width: 34px;
        height: 34px;

        img {
          width: 34px;
          height: 34px;
        }
      }
    }

    .menu-icon {
      .MuiIcon-root {
        display: flex;
        font-size: var(--fs-px-h3);
      }
    }
  }

  @media (width < $mobile) {
    .mobile {
      display: unset !important;
    }
    .not-mobile {
      display: none !important;
    }

    height: 56px;
    padding: 0 var(--spacing-sm);

    .page-profile {
      gap: var(--spacing-big) !important;
    }
  }
}

.mobile-drawer {
  .MuiBox-root {
    width: 100vw;

    .MuiList-root {
      padding: 0;
    }
  }

  .first-item {
    border-bottom: 1px solid var(--c-gray-300);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .MuiIcon-root {
      font-size: var(--fs-px-h3);
    }
  }

  .list-item-user {
    gap: var(--spacing-sm);
    border-bottom: 1px solid var(--c-gray-300);

    .MuiAvatar-root {
      width: 60px;
      height: 60px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .MuiListItem-root {
    padding: var(--spacing-sm) var(--spacing-big);
  }
  .MuiTypography-h3 {
    font-weight: 400;
    font-size: var(--t-mobile-headings-h2);
    line-height: 32px;
  }

  .MuiTypography-body1 {
    font-weight: 400;
    font-size: var(--t-mobile-headings-h4);
    line-height: 28px;
    color: var(--c-gray-900);
  }

  .MuiTypography-body2 {
    font-weight: 500;
    font-size: var(--t-mobile-paragraph-large);
    line-height: 24px;
    color: var(--c-primary);
  }

  .MuiTypography-h4 {
    font-weight: 500;
    font-size: var(--t-mobile-headings-h2);
    line-height: 28px;
    // color: var(--c-primary);
    cursor: pointer;
  }

  .flag-button {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxx-sm);
    cursor: pointer;

    .MuiIcon-root {
      font-size: var(--t-button-normal);
      color: var(--c-primary);
    }
  }
}
