.chart-accordion {
    padding: var(--spacing-x-sm) var(--spacing-sm);
    border-bottom: 1px solid var(--c-gray-300);

    &.Mui-expanded {
        margin: 0 !important;
    }

    .MuiAccordionSummary-root {
        padding: 0;

        .MuiAccordionSummary-content {
            margin: 0;
        }
    }

    .accordion-summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        @media (max-width: 449px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .summary-title {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-xxx-sm);

            .MuiTypography-body2 {
                font-size: var(--fs-px-body2);
                font-weight: 600;
                line-height: 20px;
                color: var(--c-gray-900);
            }

            .body3 {
                font-size: var(--fs-px-table);
                font-weight: 400;
                line-height: 17px;
                color: var(--c-gray-800);
            }
        }

        .summary-extras {
            display: flex;
            align-items: center;

            .summary-additionals {
                margin-left: var(--spacing-sm);

                @media (max-width: 449px) {
                    margin-left: 0;
                }
            }

            .summary-state {
                margin: 0px 16px;
            }
        }
    }

    .subtitle {
        display: flex;
        align-items: center;

        .channel-icon {
            height: 16px;
            margin-right: .5rem;
        }
    }
    
}
