.status-legend {
    display: flex;
    gap: var(--spacing-xxx-big);
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;

    @media (max-width: 720px) {
        gap: var(--spacing-sm);
    }

    .item {
        display: flex;
        align-items: center;
        gap: var(--spacing-xxx-sm);

        .MuiIcon-root {
            font-size: var(--t-desktop-headings-h4);

            &.ok {
                color: var(--c-success);
            }
            &.info {
                color: var(--c-info-dark);
            }
            &.warning {
                color: var(--c-warning);
            }
            &.error {
                color: var(--c-error);
            }
        }

        .MuiTypography-body2 {
            font-size: var(--t-desktop-paragraph-small);
            font-weight: 400;
            line-height: 20px;
            color: var(--c-gray-900);
        }
    }
}
